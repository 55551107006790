<template>
  <web-layout :title="$t('home.homeTitle')">
    <template v-slot:extend>
      <el-button style="vertical-align: top" icon="el-icon-setting-solid" @click="settingWay">{{ $t('home.setting') }}</el-button>
      <el-button style="vertical-align: top" class="switch-button" :disabled="!$store.getters.selfregister" @click="switchPropertyState">
        <el-switch :value="propertyState" :disabled="!$store.getters.selfregister"></el-switch>
        <span style="font-size: 14px; vertical-align: middle; margin-left: 5px">{{ $t('home.allProperty') }}</span>
      </el-button>
      <!--      <span id="guide_btns" style="margin-left: 10px">-->
      <!--        <el-button icon="el-icon-guide" @click="showGuide">{{ $t('webLayout.showGuideName') }}</el-button>-->
      <!--        <el-button icon="el-icon-tutorial-video" @click="showTutorialVideos(false)">{{ $t('webLayout.ViewTutorialVideos') }}</el-button>-->
      <!--      </span>-->
    </template>
    <template>
      <div class="account-data-box">
        <div class="title">
          {{ $t('home.homeAccountData') }}
          <span v-if="propertyState">{{ $t('home.homeAccountDataAll') }}</span>
          <span v-else-if="$store.getters.headerPedetail">{{ $t('home.homeAccountDataCurrent', { property: $store.getters.headerPedetail.description }) }}</span>
        </div>
        <div class="account-box">
          <div class="account-item-box" @click="getAccountDetail('pe')" v-if="propertyState">
            <span>{{ accountInfo.pe_count || 0 }}</span>
            {{ $t('home.proentity') }}
          </div>
          <div class="account-item-box" @click="getAccountDetail('acc')">
            <span>{{ accountInfo.acc_count || 0 }}</span>
            {{ $t('home.homeAccount') }}
          </div>
          <div class="account-item-box" @click="getAccountDetail('loc')">
            <span>{{ accountInfo.loc_count || 0 }}</span>
            {{ $t('home.homeLoc') }}
          </div>
          <div class="account-item-box" @click="getAccountDetail('rctask')">
            <span>{{ accountInfo.rctask_count || 0 }}</span>
            {{ $t('home.instask') }}
          </div>
          <div class="account-item-box" @click="getAccountDetail('wotemp')">
            <span>{{ accountInfo.wotemp_count || 0 }}</span>
            {{ $t('home.woTemplate') }}
          </div>
        </div>
      </div>

      <div class="fixed-chart-box">
        <div class="fixed-chart-item-box">
          <div class="name">{{ $t('home.homeRcWoTit') }}</div>
          <div class="content" ref="rcwo"></div>
        </div>
        <div class="fixed-chart-item-box">
          <div class="name">{{ $t('home.homeWoTit') }}</div>
          <div class="content" ref="wo"></div>
        </div>
        <div class="fixed-chart-item-box">
          <div class="name">{{ $t('home.homeIrTit') }}</div>
          <div class="content" ref="ir"></div>
        </div>
      </div>

      <template v-if="dashboardlist.length > 0">
        <div class="chart-nav-title">
          {{ $t('home.meter') }}
        </div>

        <div class="meter-chart-box">
          <template v-for="(itemChar, itemCharKey) in dashboardlist">
            <div class="meter-chart-item-box" v-for="(itemCharArr, itemCharKeyArr) in itemChar.index" :key="itemCharKey + '' + itemCharKeyArr">
              <div class="header">
                <el-tooltip :content="`(${itemCharArr.indexdec})${itemChar.cmdesc}`" placement="top" effect="light">
                  <div class="title"><i class="icon" :class="showMeterChartIcon(itemCharArr.indexcode)"></i>({{ itemCharArr.indexdec }}){{ itemChar.cmdesc }}</div>
                </el-tooltip>
                <div class="time">{{ friendlyFormatTime(itemCharArr.newreadingdate) }}</div>
                <div class="title-btn">
                  <!-- 红外线不显示收起、展开和切换，只显示更多 -->
                  <template v-if="itemCharArr.indexcode != 'PIR'">
                    <el-tooltip v-if="itemCharArr.chartType != '3'" :content="$t('home.unfold')" placement="top" effect="light">
                      <el-button type="text" icon="el-icon-meter-unfold" @click="meterUnfold(itemCharArr, itemCharKey, itemCharKeyArr)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-else :content="$t('home.fold')" placement="top" effect="light">
                      <el-button type="text" icon="el-icon-meter-fold" @click="meterFold(itemCharArr, itemCharKey, itemCharKeyArr)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="itemCharArr.chartType != '3'" :content="$t('home.switchChartTip')" placement="top" effect="light">
                      <el-button type="text" icon="el-icon-meter-switch" @click="changeChartType(itemCharArr, itemCharKey, itemCharKeyArr)"></el-button>
                    </el-tooltip>
                  </template>
                  <el-tooltip :content="$t('common.more')" placement="top" effect="light">
                    <el-button type="text" icon="el-icon-more" @click="openMeterChartShow(itemCharArr)"></el-button>
                  </el-tooltip>
                  <!-- <el-tooltip v-if="itemCharArr.indexcode == 'PIR' || itemCharArr.chartType != 'normal'" :content="$t('common.more')" placement="top" effect="light">
                    <el-button type="text" icon="el-icon-more" @click="openMeterChartShow(itemCharArr)"></el-button>
                  </el-tooltip> -->
                </div>
              </div>
              <div class="content-wrapper">
                <div class="content" v-if="itemCharArr.chartType == '1' || itemCharArr.chartType == '3'">
                  <div class="limit-box" v-if="itemCharArr.newreading != '' && itemCharArr.indexcode != 'PIR'">
                    <p>
                      <span class="limit-box-label">
                        <i class="el-icon-upper-limit"></i>
                      </span>
                      <span>{{ itemCharArr.istoplimit }}</span>
                    </p>
                    <p>
                      <span class="limit-box-label">
                        <i class="el-icon-lower-limit"></i>
                      </span>
                      <span>{{ itemCharArr.islowlimit }}</span>
                    </p>
                  </div>
                  <!-- co2 -->
                  <template v-if="itemCharArr.indexcode == 'CO2'">
                    <div class="carbon-dioxide-progress common-circular">
                      <span class="bg"></span>
                      <span class="current" :style="itemCharArr | radianProgress"></span>
                      <div class="carbon-dioxide-font common-circular-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 湿度 -->
                  <template v-else-if="itemCharArr.indexcode == 'HYGRO'">
                    <div class="humidity-progress common-circular">
                      <span class="bg"></span>
                      <span class="current" :style="itemCharArr | radianProgress"></span>
                      <span class="mask"><img src="../../assets/humidity-chart.svg" /></span>
                      <div class="humidity-font common-circular-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span> <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 大气压 -->
                  <template v-else-if="itemCharArr.indexcode == 'PRES'">
                    <div class="atmospheric-pressure-progress common-circular">
                      <span class="bg"></span>
                      <span class="current" :style="itemCharArr | radianProgress"></span>
                      <span class="mask"><img src="../../assets/pressure-chart.svg" /></span>
                      <div class="atmospheric-pressure-font common-circular-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- pm25 -->
                  <template v-else-if="itemCharArr.indexcode == 'PM2_5'">
                    <div>
                      <div class="pm2-font common-progress-bar-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                      <div class="pm2-progress common-progress-bar">
                        <div class="mask"><img src="../../assets/speed-bg.svg" /></div>
                        <span :style="itemCharArr | transverseProgress"></span>
                      </div>
                    </div>
                  </template>
                  <!-- pm10 -->
                  <template v-else-if="itemCharArr.indexcode == 'PM10'">
                    <div>
                      <div class="pm10-font common-progress-bar-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                      <div class="pm10-progress common-progress-bar">
                        <div class="mask"><img src="../../assets/speed-bg.svg" /></div>
                        <span :style="itemCharArr | transverseProgress"></span>
                      </div>
                    </div>
                  </template>
                  <!-- 甲醛 -->
                  <template v-else-if="itemCharArr.indexcode == 'HCHO'">
                    <div>
                      <div class="formaldehyde-font common-progress-bar-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                      <div class="formaldehyde-progress common-progress-bar">
                        <div class="mask"><img src="../../assets/speed-bg.svg" /></div>
                        <span :style="itemCharArr | transverseProgress"></span>
                      </div>
                    </div>
                  </template>
                  <!-- tvoc -->
                  <template v-else-if="itemCharArr.indexcode == 'TVOC'">
                    <div>
                      <div class="tvoc-font common-progress-bar-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                      <div class="tvoc-progress common-progress-bar">
                        <div class="mask"><img src="../../assets/speed-bg.svg" /></div>
                        <span :style="itemCharArr | transverseProgress"></span>
                      </div>
                    </div>
                  </template>
                  <!-- 光照 -->
                  <template v-else-if="itemCharArr.indexcode == 'LIGHT'">
                    <div>
                      <div class="illumination-font common-progress-bar-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                      <div class="illumination-progress common-progress-bar">
                        <span :style="itemCharArr | transverseProgress"></span>
                      </div>
                    </div>
                  </template>
                  <!-- 红外感应 -->
                  <template v-else-if="itemCharArr.indexcode == 'PIR'">
                    <div :ref="itemCharArr.id" style="height: 100%"></div>
                  </template>
                  <!-- 温度 -->
                  <template v-else>
                    <div class="temperature-progress common-circular">
                      <span class="bg"></span>
                      <span class="current" :style="itemCharArr | radianProgress"></span>
                      <div class="temperature-font common-circular-font">
                        <span :class="{ 'is-reachlimit': itemCharArr.newreading <= itemCharArr.islowlimit || itemCharArr.newreading >= itemCharArr.istoplimit }">{{ itemCharArr.newreading || 0 }}</span>
                        <span class="icon">{{ itemCharArr.unit }}</span>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="content2" v-if="(itemCharArr.chartType == '2' || itemCharArr.chartType == '3') && itemCharArr.indexcode != 'PIR'">
                  <!-- co2 -->
                  <template v-if="itemCharArr.indexcode == 'CO2'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- 湿度 -->
                  <template v-else-if="itemCharArr.indexcode == 'HYGRO'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- 大气压 -->
                  <template v-else-if="itemCharArr.indexcode == 'PRES'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- pm25 -->
                  <template v-else-if="itemCharArr.indexcode == 'PM2_5'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- pm10 -->
                  <template v-else-if="itemCharArr.indexcode == 'PM10'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- 甲醛 -->
                  <template v-else-if="itemCharArr.indexcode == 'HCHO'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- tvoc -->
                  <template v-else-if="itemCharArr.indexcode == 'TVOC'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- 光照 -->
                  <template v-else-if="itemCharArr.indexcode == 'LIGHT'">
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                  <!-- 红外感应 -->
                  <!-- <template v-else-if="itemCharArr.indexcode == 'PIR'">
                    <div :ref="itemCharArr.id" style="height: 100%"></div>
                  </template> -->
                  <!-- 温度 -->
                  <template v-else>
                    <div class="chart_box" :ref="itemCharArr.id"></div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>

      <!-- 当前物业 / 所有物业 -->
      <template v-if="!isHideTitle">
        <div class="chart-nav-title">{{ $t('home.chartTitle') }}（{{ chartTimeRangeLabel }}）</div>
        <div class="line-chart-box">
          <!-- 巡检计划执行情况 / 物业下巡检单执行情况汇总 -->
          <div class="line-chart-item-box" v-if="isChartShow('chart1')">
            <div class="name">{{ $t('home.homeRcTaskTit') }}</div>
            <div class="content" ref="percwo"></div>
          </div>
          <!-- 工单模板执行情况 / 物业下工单执行情况 -->
          <div class="line-chart-item-box" v-if="isChartShow('chart2')">
            <div class="name">{{ $t('home.homeWoTaskTit') }}</div>
            <div class="content" ref="pewo"></div>
          </div>
          <!-- 物业下事件报告执行情况（按巡检点统计前5个） / 物业下事件报告执行情况 -->
          <div class="line-chart-item-box" v-if="isChartShow('chart3')">
            <div class="name">{{ $t('home.homePeIrTit') }}</div>
            <div class="content" ref="peir"></div>
          </div>
          <!-- 异常巡检点数量（按巡检点统计前5个） / 异常巡检点数量 -->
          <div class="line-chart-item-box" v-if="isChartShow('chart4')">
            <div class="name">{{ $t('home.homeAbLocTit') }}</div>
            <div class="content" ref="abloc"></div>
          </div>
          <!-- 人员巡检情况 / 人员巡检情况（按物业统计前5个新增） -->
          <div class="line-chart-item-box" v-if="isChartShow('chart5')">
            <div class="name">{{ $t('home.homeHrRcTit') }}</div>
            <div class="content" ref="hrrc"></div>
          </div>
        </div>
      </template>
    </template>

    <!-- 配置仪表 -->
    <el-drawer :title="$t('homeConfigDashboard.configDashboard')" :destroy-on-close="true" :visible.sync="configDashboardVisible">
      <home-config-dashboard-page v-if="configDashboardVisible" @setSuccess="setConfigSuccess" :editData="configEditData" :chartsyslist="chartsyslist" @cancelConfigClick="cancelConfigClick"></home-config-dashboard-page>
    </el-drawer>

    <!-- 仪表详情图表 -->
    <el-dialog :visible.sync="dialogMeterChartVisible" destroy-on-close width="750px" append-to-body custom-class="meter-dialog">
      <div slot="title" class="title" v-if="meterChartDetail && meterChartDetail.indexdec"><i class="icon" :class="showMeterChartIcon(meterChartDetail.indexcode)"></i>({{ meterChartDetail.indexdec }}){{ meterChartDetail.cmdesc }}</div>
      <home-meter-show-page v-if="dialogMeterChartVisible" :editData="meterChartDetail"></home-meter-show-page>
    </el-dialog>

    <!-- 显示用户数据详情 -->
    <el-dialog :visible.sync="dialogAccountDetailVisible" destroy-on-close width="750px" append-to-body :title="accountDetailTit">
      <home-account-detail-page v-if="dialogAccountDetailVisible" :propertyState="propertyState" :dialogAccountType="dialogAccountType"></home-account-detail-page>
    </el-dialog>

    <!-- 视频教程列表弹窗 -->
    <el-dialog :visible.sync="tutorialVideoConfig.visible" destroy-on-close width="750px" append-to-body custom-class="tutorial-video-dialog" @close="tutorialVideoClose">
      <div v-loading="tutorialVideoConfig.loading">
        <!-- 基础 -->
        <div class="title">{{ $t('home.tutorialTitle') }}</div>
        <div class="subtitle">{{ $t('home.tutorialSubtitle', { num: tutorialVideoConfig.list.length }) }}</div>
        <div class="content">
          <a :href="vItem.link" target="_blank" v-for="(vItem, vIndex) in tutorialVideoConfig.list" :key="vIndex">{{ $t('home.tutorialStep', { num: vIndex + 1 }) + ' : ' + vItem.coursename }}</a>
          <div class="empty-tips" v-if="!tutorialVideoConfig.list.length">{{ $t('home.tutorialIsEmpty') }}</div>
        </div>
        <!-- 其他 -->
        <div class="title">{{ $t('home.tutorialOtherTitle') }}</div>
        <div class="subtitle">{{ $t('home.tutorialOtherSubtitle') }}</div>
        <div class="content">
          <a :href="vItem.link" target="_blank" v-for="(vItem, vIndex) in tutorialVideoConfig.otherList" :key="vIndex">{{ $t('home.functionStep', { num: vIndex + 1 }) + ' : ' + vItem.coursename }}</a>
          <div class="empty-tips" v-if="!tutorialVideoConfig.otherList.length">{{ $t('home.tutorialIsEmpty') }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-popover v-if="tutorialVideoConfig.showAlwaysTips" placement="top" width="300" trigger="hover" :content="$t('home.tutorialPopTips')">
          <div slot="reference">{{ $t('home.alwaysAppear') }}</div>
        </el-popover>
        <el-button type="primary" @click="tutorialVideoClose">{{ $t('common.close') }}</el-button>
      </div>
    </el-dialog>

    <!--    <new-guide-component></new-guide-component>-->
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
